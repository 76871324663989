export default defineNuxtRouteMiddleware(async (to) => {
  const settings = useSettings();

  const diners = settings.value.customer.diners;

  if (diners.length === 0) {
    return navigateTo(
      {
        name: "diners",
      },
      { replace: true },
    );
  }

  if (to.params.diner_uuid) {
    const diner = diners.find((diner) => diner.uuid === to.params.diner_uuid);

    if (!diner) {
      return navigateTo(
        {
          name: "diners",
        },
        { replace: true },
      );
    }
  }
});
